/*
Theme Name: FastJobs
Author: FastJobs Team
Author URI: http://www.fastjobs.com.my
Description: FastJobs is a platform that connects jobseekers and employers.
*/


/*-----------------------------------------------------------------------------------*/
/*	1. Framework & Miscellaneous
/*-----------------------------------------------------------------------------------*/

body {
  background: #fff;
  color: #0a0a0a;
  font-family: 'Lato', system-ui, Arial, sans-serif !important;
  font-size: 16px;
  font-weight: normal;
  padding-top: 40px;
}

body.login-page { background: #f5f5f5; }
body.login-page h1 { font-weight: 500; }

a, a:active, a:focus, a:visited { outline: none !important; }

.link-text {
  color: #ff3e97;
  font-weight: 300;
}

.link-text > span {
  border-bottom: 1px solid #bdc6cf;
  margin-right: 5px;
}

.link-text > span.glyphicon {
  border-bottom: none;
  font-weight: 300;
}

.link-text:hover {
  color: #d62c7b;
  text-decoration: none;
}

.link-text:hover > span { border-color: #d62c7b; }
.link-text:hover > span.glyphicon { color: #d62c7b; }

.main-content { min-height: 400px; }

.simple-page .simple-content { padding: 40px 20px; }

.tag-applicant {
  color: #fff;
  display: inline-block;
  font-size: 20px;
  font-weight: 500;
  margin: 10px 5px;
  padding: 5px 35px;
  text-align: center;
  text-transform: uppercase;
  -webkit-border-radius: 99px;
  -moz-border-radius: 99px;
  border-radius: 99px;
}

.tag-applicant.suitable { background-color: rgba(0, 195, 117, 0.75); /*background: #00c375;*/ }
.tag-applicant.not-suitable { background-color: rgba(229, 73, 78, 0.75); /*background: #e5494e;*/ }

.note-helper-option {
  background: #f7f7f7;
  border: 1px solid #D6DBE4;
  color: #333;
  display: inline-block;
  font-size: 13px;
  padding: 3px 15px;
  margin: 5px 3px 5px 0;
  border-radius: 99px;
  -moz-border-radius: 99px;
  -webkit-border-radius: 99px;
}
.note-helper-option:hover, 
.note-helper-option:focus {
  background-color: #FE8AC7;
  border-color: #f285bd;
  color: #fff;
  text-decoration: none;
}
.note-helper-option:focus {
  background: #fff;
  color: #333;
  text-decoration: none;
}
.border-left { border-left: 1px solid #ccc; }
.border-right { border-right: 1px solid #ccc; }
.text-center-md { text-align: center; }

.btn-myaccount {
  background-color: #263F88;
  border-color: #152A66;
}

.btn-myaccount:hover,
.btn-myaccount:focus,
a.btn-myaccount:hover,
a.btn-myaccount:focus {
  background: #152A66;
  border-color: #263F88;
}

a.btn-myaccount { color: #fff !important; }

/* Chrome, Safari, Edge, Opera */
#inq-form input::-webkit-outer-spin-button,
#inq-form input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
#inq-form input[type=number] {
  -moz-appearance: textfield;
}


/*-----------------------------------------------------------------------------------*/
/*	2. Navigation
/*-----------------------------------------------------------------------------------*/

.navbar-brand {
  height: 42px;
  padding: 5px 0;
}

.navbar-brand > span.logo {
  background: url('../../../img/frontend/fastjobs-logo.png') no-repeat left top;
  background-size: cover;
  display: block;
  height: 42px;
  overflow: hidden;
  text-indent: -900px;
  width: 155px;
}

.navbar-nav > li { position: relative; }
.navbar-nav > li > a > span.nav-sublabel {
  display: block;
  font-size: 11px;
  position: absolute;
  text-transform: uppercase;
  top: 0;
}

.navbar > .navbar-main {
  background: #1f2c52;
  color: #bec5d5;
}

#navbar-search {
  position: fixed;
  top: 60px;
  transition: top 0.2s ease-in-out;
  width: 100%;
  z-index: 6;
}

#navbar-search.nav-up { top: -80px; }

.navbar-search {
  background: #ecf0f3;
  border-bottom: 3px solid #ff87c7;
  color: #000;
  margin-bottom: 20px;
  padding-bottom: 10px;
  padding-top: 10px;
}

.navbar-search .container { padding-top: 0; }

.navbar-search ul.radio { margin: 5px 0 0; }
.navbar-search ul.radio > li {
  display: inline;
  margin: 0 15px;
}
.navbar-search ul.radio > li:last-child { margin-right: 0; }

.navbar-search ul.radio > li.divider {
  border-right: 1px solid #cacdcf;
  margin: 0 10px 0 0;
  padding: 5px 0;
}

.navbar-search ul.radio label {
  font-weight: 500;
  vertical-align: middle;
}

.navbar-search .location-option {
    padding: 20px 10px;
    width: 490px !important;
}
.navbar-search .location-option li {
  cursor: pointer;
  padding: 5px 10px;
}

.navbar-search .location-option li:hover,
.navbar-search .location-option li.active { font-weight: 500; }

.navbar-search .btn.with-pref {
  border-color: #1f659b;
  border-width: 2px;
  font-weight: 500;
  padding: 5px 12px;
}

.navbar-search .with-pref .input-group-addon,
.navbar-search .with-pref .form-control {
  border-color: #1f659b;
  border-width: 2px;
  font-weight: 500;
}

.navbar-search .remove-search-keyword {
  color: #1f659b;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
}

.nav-user a.dropdown-toggle {
  padding-bottom: 0 !important;
  padding-top: 10px !important;
}

.nav-user img.user-photo { margin-right: 10px; }

.nav-user .nav-user-menu {
  list-style: none;
  margin: 0;
  padding: 0;
}

.nav-user .nav-user-menu > li {
  margin: 0;
  padding: 8px 55px;
}

.nav-user .nav-user-menu a {
  color: #bec5d5;
  font-weight: 300;
}

.nav-user .nav-user-menu a:hover {
  color: #fff;
  text-decoration: none;
}

/*-----------------------------------------------------------------------------------*/
/*	3. Banner hero
/*-----------------------------------------------------------------------------------*/

.emp-hero {
  background: url('../../../img/employer/emp-hero.jpg') no-repeat top center;
  background-size: cover;
  height: 660px;
  position: relative;
  width: 100%;
}

.hero-content {
  background: rgb(0, 0, 0);
  background: rgba(0, 0, 0, .4);
  bottom: 0;
  color: #fff;
  padding-bottom: 90px;
  padding-top: 30px;
  position: absolute;
  text-align: center;
  width: 100%;
}

.hero-content h1 {
  font-size: 50px;
  font-weight: 500;
  margin: 0 0 10px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
  text-transform: uppercase;
  font-family: 'Poppins', system-ui, Arial, sans-serif !important;
}

.hero-content h3 {
  font-weight: 300;
  font-size: 22px;
  line-height: 28px;
  margin-bottom: 20px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
}

.hero-content p {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 30px;
  text-shadow: 0px 1px 1px rgba(0, 0, 0, 0.7);
}

.hero-content .btn {
  font-size: 20px;
  font-weight: 400;
  padding: 12px 36px;
}

.scroll-down {
  bottom: -1px;
  height: 40px;
  position: absolute;
  text-align: center;
  z-index: 5;
  width: 100%;
}

.scroll-down .btn-arrow-down {
  background: url('../../../img/frontend/icon-arrow-down.png') no-repeat;
  background-size: 112px 40px;
  display: inline-block;
  height: 40px;
  width: 112px;
}


/*-----------------------------------------------------------------------------------*/
/*	4. Feature contents
/*-----------------------------------------------------------------------------------*/

.feature {
  border-bottom: 1px #d5dadf solid;
  overflow: hidden;
  padding-top: 5rem;
}

.feature.jobseeker {
  max-height: 580px;
}

.feature h2 {
  font-size: 26px;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.feature.light-gray {
  background: #f5f5f5;
}

.feature.candidates .browser,
.feature.candidates .browser .frame img{
  width: 100%;
}

.feature .feature-content { margin-top: 150px; }

.feature.dashboard { border-bottom: none; }

/*-----------------------------------------------------------------------------------*/
/*	5. Signup
/*-----------------------------------------------------------------------------------*/
.signup {
  background: #151f3a;
  color: #fff;
  min-height: 500px;
  padding-bottom: 50px;
  padding-top: 30px;
}

.signup .btn {
  margin-top: 10px;
}

.verifycode > .form-group > label { display: block; }
.verifycode .captcha-refresh { cursor: pointer; }
.verifycode img {
  border: 1px solid #ccc;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  width: 45%;
}

ul.emp-feat-list { margin: 0; padding-left: 15px; }
.emp-feat-list > li {
  margin-bottom: 20px;
  margin-top: 20px;
}

/*-----------------------------------------------------------------------------------*/
/*  6. Video
/*-----------------------------------------------------------------------------------*/
.video-container { background-color: #fff; padding: 50px 0 80px; }
.video-container h2 { margin-bottom: 30px; }
.media-container { position: relative; padding-bottom: 56.25%; height: 0; }
.media-container iframe { position: absolute; top: 0; left: 0; width: 100%; height: 100%; }

/*-----------------------------------------------------------------------------------*/
/*	Store Download Buttons
/*-----------------------------------------------------------------------------------*/
.btn-download {
  background: url('../../../img/frontend/fj-img-sprite.png') no-repeat;
  cursor: pointer;
  display: inline-block;
  height: 60px;
  text-indent: -9999px;
  width: 202px;
}

.btn-ios {
  background: url(../../../img/frontend/btn-download-ios.png) no-repeat left top !important;
  background-size: contain !important;
  display: block;
  height: 43px;
  overflow: hidden;
  text-indent: -900px;
  width: 150px;
}

.btn-android {
  background: url(../../../img/frontend/btn-download-android.png) no-repeat left top !important;
  background-size: contain !important;
  display: block;
  height: 43px;
  overflow: hidden;
  text-indent: -900px;
  width: 150px;
}

.btn-appgallery {
  background: url(../../../img/frontend/btn-download-appgallery.png) no-repeat left top !important;
  background-size: contain !important;
  display: block;
  height: 43px;
  overflow: hidden;
  text-indent: -900px;
  width: 150px;
}

.btn-download.btn-sm {
  height: 47px;
  width: 150px;
}

.btn-download.btn-ios { background-position: 0 0; }
.btn-download.btn-android { background-position: 0 -64px; }

.btn-download.btn-sm.btn-ios { background-position: 0 -339px; }
.btn-download.btn-sm.btn-android { background-position: 0 -384px; }

/*-----------------------------------------------------------------------------------*/
/*	Error page
/*-----------------------------------------------------------------------------------*/
.error-page { margin-bottom: 90px; min-height: 500px; }
.error-page .error-content { padding: 40px 20px; }

/*-----------------------------------------------------------------------------------*/
/*	Footer
/*-----------------------------------------------------------------------------------*/
.footer {
  background: #293153;
  color: #dddddd;
  font-size: 14px;
  padding-bottom: 50px;
  padding-top: 20px;
}

.footer .list-title { 
  font-weight: bold;
	margin-bottom: 16px;
	text-transform: uppercase;
}

body.home .footer { margin-top: 0; }

.footer a { color: #fff; font-weight: bold; }

.footer a:hover,
.footer a:focus{
  color: #fa4094;
  text-decoration: none;
}

.footer .navbar-brand { 
  clear: both;
  float: none;
}

.footer ul {
  margin-bottom: 30px;
}

.footer ul > li.list-title {
  color: #7f8594;
  font-size: 14px;
  text-transform: uppercase;
}

.footer ul > li { margin: 8px 0; }

.footer li.social-network {
  display: inline;
  margin-right: 15px;
}

.footer li.social-network > a {
  display: inline-block;
  color: #fff;
  font-size: 28px;
}
.footer li.social-network > a:hover {
    color: #fa4094;
}
.footer li.social-network > a.facebook { background-position: 0 -127px; }
.footer li.social-network > a.facebook:hover { background-position: 0 -183px; }
.footer li.social-network > a.instagram { background-position: -56px -127px; }
.footer li.social-network > a.instagram:hover { background-position: -56px -183px; }



/*-----------------------------------------------------------------------------------*/
/*	Responsive - Tablet
/*-----------------------------------------------------------------------------------*/
@media (max-width: 991px) and (min-width: 768px) {
  body { padding-top: 20px; }
  body.login-page { background: #fff; }

  .navbar-brand {
    background-size: 142px 40px;
    height: 40px;
    width: 142px;
  }

  .emp-hero {
    background: url('../../../img/employer/emp-hero.jpg') no-repeat top center;
    background-size: cover;
    height: 480px;
    width: 100%;
  }

  .scroll-down {
    bottom: -11px;
  }

  .scroll-down .btn-arrow-down {
    height: 29px;
    width: 80px;
    background-size: 80px 29px;
  }

  .hero-content {
    padding-bottom: 60px;
    padding-top: 20px;
  }

  .hero-content h1 { font-size: 36px; }

  .hero-content h3 { font-size: 20px; }

  .hero-content p { font-size: 18px; }

  .hero-content .btn {
    font-size: 18px;
    padding: 12px 24px;
  }

  .feature { padding-top: 2rem; }
  .feature .feature-content { font-size: 15px; margin-top: 60px; }
  .feature h2 { font-size: 20px; }
  
  .login-page .panel-default {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }

  .footer .navbar-brand {
    background-size: 120px 32px;
    height: 32px;
    margin-top: 7px;
    width: 120px;
  }
  
  .error-page { min-height: 550px; }
  .text-center-md { text-align: left; }
}

/*-----------------------------------------------------------------------------------*/
/*	Responsive - Mobile
/*-----------------------------------------------------------------------------------*/
@media (max-width: 767px) {
  body { font-size: 15px; padding-top: 50px; }
  
  body.login-page { background: #fff; }
  body.login-page p { line-height: 34px; }

  .navbar .container { padding-top: 10px; }
  
  .navbar > .navbar-main { min-height: 50px; }
  
  .navbar-brand { height: 40px; }

  .navbar-brand > span.logo-icon {
    background: url('../../../img/frontend/fastjobs-logo-icon.png') no-repeat left top;
    background-size: cover;
    display: block;
    height: 36px;
    margin: -6px 10px 0;
    overflow: hidden;
    text-indent: -900px;
    width: 32px;
  }

  .emp-hero .navbar-brand {
    background: url('../img/fastjobs-logo-white.png') no-repeat top left;
    background-size: 110px 31px;
    height: 31px;
    margin-left: 5px;
    width: 110px;
  }

  .emp-hero {
    background-size: cover;
    height: 360px;
  }

  .scroll-down {
    bottom: -20px;
  }
  .scroll-down .btn-arrow-down {
    height: 21px;
    width: 60px;
    background-size: 60px 21px;
  }

  .hero-content {
    padding-bottom: 40px;
    padding-top: 10px;
  }
  .hero-content h1 {
    font-size: 26px;
    line-height: 30px;
  }
  .hero-content h3 {
    font-size: 16px;
    line-height: 19px;
  }
  .hero-content p {
    font-size: 16px;
    line-height: 20px;
    margin-bottom: 10px;
  }
  .hero-content .btn {
    font-size: 16px;
    padding: 8px 15px;
  }

  .feature { padding-top: 2rem; }
  .feature .feature-content {
    margin: 30px 0;
    text-align: center;
  }
  .feature h2 { font-size: 20px; }

  .signup { padding-top: 20px; }
  
  .container h2 { font-size: 22px; }

  .app-screen {
    -webkit-box-shadow: 0px 20px 30px -23px rgba(0,0,0,0.34);
    -moz-box-shadow: 0px 20px 30px -23px rgba(0,0,0,0.34);
    box-shadow: 0px 20px 30px -23px rgba(0,0,0,0.34);
  }
  
  .login-page .panel-default {
    border: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
  
  .btn-lg, .btn-group-lg > .btn {
    font-size: 15px;
    padding: 7px 15px;
  }

  .footer .navbar-brand {
    background-size: 120px 32px;
    height: 32px;
    width: 120px;
  }
  
  .error-page .error-content { padding: 10px 10px; }
  .error-page h2 { font-size: 18px !important; }
  .text-center-xs { text-align: center; }
  
  .simple-page .simple-content { padding: 10px 0; }

  .video-container { padding: 30px 0 60px; }
  .video-container h2 { margin-bottom: 20px; }
}


/*-----------------------------------------------------------------------------------*/
/*	Browser
/*-----------------------------------------------------------------------------------*/
.browser {
  -webkit-box-shadow:0 13px 35px rgba(98,100,112,0.25);
  -moz-box-shadow:0 13px 35px rgba(98,100,112,0.25);
  box-shadow:0 13px 35px rgba(98,100,112,0.25);
  border:1px solid #d5dadf;
  border-radius:3px;
  margin:auto;
  overflow:hidden;
  position:relative;
  display:inline-block;
  min-height:200px;
  max-height: 500px;
  background:#fff;
}

@media (max-width: 74.9em) {
  .browser .frame img {
    max-width:100%;
    width:100%;
    height:auto;
  }
}

.browser .top-bar {
  background:#fff;
  border-bottom:1px solid #d5dadf;
  height:24px;
  border-radius:4px 4px 0 0;
}

@media (min-width: 48em) {
  .browser .top-bar {
    height:36px;
  }
}



.browser .top-bar .lights{
  list-style:none;
  position:absolute;
  padding:0;
  left:12px;
  top:0;
}

@media (min-width: 48em) {
  .browser .top-bar .lights {
    left:10px;
    top:7px;
  }
}

.browser .top-bar .lights li {
  -webkit-box-shadow:inset 0 1px 1px rgba(0,0,0,0.12);
  -moz-box-shadow:inset 0 1px 1px rgba(0,0,0,0.12);
  box-shadow:inset 0 1px 1px rgba(0,0,0,0.12);
  display:inline-block;
  margin-right:6px;
  height:6px;
  width:6px;
  border-radius:100%;
  border:1px solid #da4b47;
}

.browser .top-bar .lights li:nth-child(1) {
  background:#f66560;
}

.browser .top-bar .lights li:nth-child(2){
  background:#f9c964;
  border-color:#dcac47;
}

.browser .top-bar .lights li:nth-child(3) {
  background:#30c75d;
  border-color:#1fa748;
}

@media (min-width: 48em){
  .browser .top-bar .lights li{
    margin-right:8px;
    height:10px;
    width:10px;
  }
}


@media (max-width: 991px) and (min-width: 768px) {
  .browser .top-bar { height:30px; }
  .browser .top-bar .lights { top:3px; }
  .browser .top-bar .lights li{
    height:8px;
    width:8px;
  }
  .video-container { padding: 30px 0 60px; }
}

.startup-flag label { display: inline-block !important; }
.submit-agree-terms {
  font-size: 12px;
  margin: 10px 0;
  text-align: center;
}

/*-----------------------------------------------------------------------------------*/
/*	SRA Banner
/*-----------------------------------------------------------------------------------*/
.sra-banner-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
}

.sra-banner-image {
  max-width: 1425px;
  height: auto;
}

@media(max-width: 1425px) {
  .sra-banner-wrapper {
    display: block;
  }

  .sra-banner-image {
    width: 100%;
  }
}